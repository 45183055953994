import React, { useEffect, useRef, useState } from "react";
import Avatar from "../../components/Avatar";
import RankingServices from "../../Services/Ranking/Player";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { AppTableRanking } from "../../components/app/admin/common/GlobalStyles";
import { setClassPosition } from "../../libs/utils";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Grid from "@material-ui/core/Grid";
import newIconStar from "../../assets/img/newIconStar.png";
import iconStar from "../../assets/img/iconStar.png";
import "./styles.scss";

const UserRanking = (props) => {
  return (
    <div
      className={
        "user-ranking-wrapper " +
        (props.current ? "user-ranking-wrapper-active" : "")
      }
    >
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "0 1em",
        }}
      >
        <span className="name">
          <Avatar
            size={"2em"}
            style={{ borderWidth: "2px" }}
            avatar={props.image}
          />
          <span className="ml-custom-1">
            {props.name == null
              ? props.username
              : `${props.name} ${props.lastName}`}
          </span>
        </span>

        <span className="points-custom">
          <img
            src={newIconStar}
            alt="Custom Icon"
            style={{ width: "15px", height: "15px", marginRight: "4px" }}
          />
          {props.points}
        </span>
      </span>
    </div>
  );
};

const UserMainRanking = (props) => {
  let rankingClass = "";

  if (props.position == 1) {
    rankingClass = "first-position-ranking";
  } else if (props.position == 2) {
    rankingClass = "second-position-ranking";
  } else if (props.position == 3) {
    rankingClass = "third-position-ranking";
  } else {
    rankingClass = "other-position-ranking";
  }

  return (
    <div
      className={
        "user-ranking-wrapper " +
        (props.current ? "user-ranking-wrapper-active" : "")
      }
      tabIndex={props.current ? "-1" : ""}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          //margin: "0 1em",
        }}
      >
        <span className="name-custom-ranking">
          {props.position && (
            <div
              className={`mr-1 span-position-ranking ${rankingClass}`}
              ref={(el) => {
                if (el) {
                  el.style.setProperty("color", "white", "important");
                }
              }}
            >
              {props.position}
            </div>
          )}

          <Avatar
            size={"2em"}
            style={{ borderWidth: "2px" }}
            avatar={props.image}
          />

          <span className="name">
            {props.name} {props.lastName}
          </span>

          <span className="features">
            {props.email == null ? "---" : props.email}
          </span>

          <span className="features">{props.teamName}</span>

          <span className="features">{props.isExternal == null ? "---" : props.isExternal}</span>
        </span>

        {props.insigniaCount && (
          <span className="points-custom">{props.insigniaCount}</span>
        )}
        <span className="points-custom">
          <img
            src={newIconStar}
            alt="Custom Icon"
            style={{
              width: "15px",
              height: "15px",
              marginRight: "4px",
              marginLeft: "3px",
            }}
          />
          {props.points != undefined ? props.points : 0}
        </span>
      </span>
    </div>
  );
};

const Ranking = (props) => {
  const [mainRankings, setMainRanking] = useState(null);
  const [historicalRankings, setHistoricalRanking] = useState(null);
  const [badgeRankings, setBadgeRanking] = useState(null);
  const [metricRankings, setMetricRanking] = useState(null);
  const [sesion, setSesion] = useState(null);
  const mainRankingRef = useRef(null);
  const historicalRankingsRef = useRef(null);
  const badgeRankingsRef = useRef(null);
  const metricRankingRef = useRef(null);
  const { t } = useTranslation();
  const [awardCategory, setAwardCategory] = useState("");
  useEffect(() => {
    setSesion(JSON.parse(localStorage.getItem("sesion")));

    var fechaInicial = new Date(new Date().getFullYear(), 0, 1);
    var fechaFinal = new Date(new Date().getFullYear() + 1, 0, 1);

    var año_inicial = fechaInicial.getFullYear();
    var año_final = fechaFinal.getFullYear();
    var mes = ("0" + (fechaInicial.getMonth() + 1)).slice(-2);
    var dia = ("0" + fechaInicial.getDate()).slice(-2);
    var fechaFormateadaInicial =
      año_inicial + "-" + mes + "-" + dia + 'T00:00:00';
    var fechaFormateadaFinal = año_final + "-" + mes + "-" + dia + 'T00:00:00';

    

      RankingServices.GetMainRankingAwarCategory()
      .then((response) => {
        setMainRanking(response.data.content);
      })
      .catch((error) => {
        setMainRanking([]);
      });

    RankingServices.GetHistoricalRankingAwarCategory(fechaFormateadaInicial, fechaFormateadaFinal)
      .then((response) => {
        setHistoricalRanking(response.data.content);
      })
      .catch((error) => {
        setHistoricalRanking([]);
      });
  }, []);

  const sortingData = (ranking) => {
    if (!ranking || !sesion) return [];

    const sesionId = sesion.user.id;

    const sesionUserIndex = ranking.findIndex(
      (rank) => rank.user.id === sesionId
    );

    // userInHeadRanking
    // el tercero o el antepenultimo exactamente
    if (sesionUserIndex === 2 || sesionUserIndex === ranking.length - 3) {
      return ranking;
    }

    const ranksWithSamePosition = ranking.filter(
      (rank) => rank.position === ranking[sesionUserIndex].position
    );

    // verifica que haya mas usuarios con la misma posicion
    // si existen mas usuarios se ordena de primero el usuario
    // logueado dentro de la posicion repetida
    const userRanking =
      ranksWithSamePosition.length <= 1
        ? ranking
        : [...ranking].sort((firstRank, secondRank) => {
            // en caso que el usuario dentro del ranking este en una posicion repetida

            if (firstRank.position !== secondRank.position)
              return firstRank.position - secondRank.position;

            if (sesionId === firstRank.user.id) return 0;

            if (sesionId === secondRank.user.id) {
              return 1;
            }
          });

    return userRanking;
  };

  useEffect(() => {
    if (sesion && sesion.user && sesion.user.awardCategory) {
      setAwardCategory(sesion.user.awardCategory);
    }
  }, [sesion]);

  useEffect(() => {
    if (mainRankingRef.current && mainRankingRef.current.children.length > 0) {
      const sesionUserIndex = mainRankings.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(mainRankingRef.current.children);

      if (sesionUserIndex > 2)
        mainRankingRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1].offsetTop
        );
    }

    if (
      badgeRankingsRef.current &&
      badgeRankingsRef.current.children.length > 0
    ) {
      const sesionUserIndex = badgeRankings.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(badgeRankingsRef.current.children);
      if (sesionUserIndex > 2)
        badgeRankingsRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1].offsetTop
        );
    }

    if (
      historicalRankingsRef.current &&
      historicalRankingsRef.current.children.length > 0
    ) {
      const sesionUserIndex = historicalRankings.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(
        historicalRankingsRef.current.children
      );
      if (sesionUserIndex > 2)
        historicalRankingsRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1].offsetTop
        );
    }

    if (
      metricRankingRef.current &&
      metricRankingRef.current.children.length > 0
    ) {
      const sesionUserIndex = metricRankings[0].users.findIndex(
        (rank) => rank.user.id === sesion.user.id
      );
      const collectionArray = Array.from(metricRankingRef.current.children);
      console.log("moving metric rang");
      console.log(sesionUserIndex);
      if (sesionUserIndex > 2) {
        console.log("more than 2");
        metricRankingRef.current.scroll(
          0,
          collectionArray[sesionUserIndex - 1].offsetTop
        );
      }
    }
  }, [mainRankings, historicalRankings, badgeRankings, metricRankings]);

  if (!sesion) return "";

  return (
    <div className="ranking-wrapper">
      <Grid container className="ranking-wrapper-inner">
        <Grid
          item
          className="ranking-box"
          /*md={sesion.user.company_Id != 5 ? 4 : 6}*/ xs={12}
        >
          <h3>
            {t("total-ranking-month.label", "Monthly ranking").toUpperCase()}{" "}
            {awardCategory ? ` - ${awardCategory}`.toUpperCase() : ""}
          </h3>
          {mainRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <div
              className="card-model ranking-list-content2 custom-height-metrics"
              ref={mainRankingRef}
            >
              {sortingData(mainRankings).map((data, index) => {
                return (
                  <UserMainRanking
                    position={data.position}
                    name={data.user.name}
                    lastName={data.user.lastName}
                    username={data.user.username}
                    points={data.pointBalance}
                    positionIndex={index}
                    current={sesion.user.id === data.user.id}
                    image={"/api" + data.user.image}
                    key={data.user.id}
                    insigniaIcon={false}
                    email={data.user.email}
                    teamName={data.user.teamName}
                    isExternal={
                      data.user.secondaryTeamName}
                  />
                );
              })}
            </div>
          )}
        </Grid>

        <Grid
          item
          className="ranking-box"
          /*md={sesion.user.company_Id != 5 ? 4 : 6}*/ xs={12}
          
        >
          <h3>
          </h3>
          <br></br>
          <br></br>
          <br></br>
          <h3>{t("anual-ranking.label", "Anual ranking").toUpperCase()}{" "}
          {awardCategory ? ` - ${awardCategory}`.toUpperCase() : ""}
          </h3>
          {historicalRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <AppTableRanking>
              <div className="card-model">
                <div
                  className="ranking-list-content custom-height-metrics"
                  ref={historicalRankingsRef}
                >
                  {sortingData(historicalRankings).map((data, index) => {
                    return (
                      <UserMainRanking
                        position={data.position}
                        name={data.user.name}
                        lastName={data.user.lastName}
                        username={data.user.username}
                        points={data.pointBalance}
                        positionIndex={index}
                        current={sesion.user.id === data.user.id}
                        image={"/api" + data.user.image}
                        key={data.user.id}
                        insigniaIcon={false}
                        email={data.user.email}
                        teamName={data.user.teamName}
                        isExternal={
                          data.user.secondaryTeamName}
                      />
                    );
                  })}
                </div>
              </div>
            </AppTableRanking>
          )}
        </Grid>

        {/*sesion.user.company_Id != 5 && (
          <Grid item className="ranking-box" xs={12} md={4}>
            <h3>{t("menu.rigth-list-item-badges", "Badges").toUpperCase()}</h3>
            {badgeRankings === null ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
              <AppTableRanking>
                <div className="card-model">
                  <div
                    className="ranking-list-content custom-height-metrics"
                    ref={badgeRankingsRef}
                  >
                    {sortingData(badgeRankings).map((data, index) => {
                      return (
                        <UserMainRanking
                          position={data.position}
                          name={data.user.name}
                          lastName={data.user.lastName}
                          username={data.user.username}
                          positionIndex={index}
                          current={sesion.user.id === data.user.id}
                          insigniaCount={
                            data.insigniaCount ? data.insigniaCount : "0"
                          }
                          image={"/api" + data.user.image}
                          key={data.user.id}
                          insigniaIcon={true}
                        />
                      );
                    })}
                  </div>
                </div>
              </AppTableRanking>
            )}
          </Grid>
                  )*/}
      </Grid>
      {sesion.user.company_Id != 5 && (
        <>
          {metricRankings === null ? (
            <span className="spinner-container">
              <SpinnerCircular />
            </span>
          ) : (
            <Grid container spacing={4} className="ranking-wrapper-inner">
              {metricRankings.map(({ metricConf, users }, index) => (
                <Grid item xs={12} md={6}>
                  <span className="ranking-box" key={index}>
                    <h3>{metricConf.name.toUpperCase()}</h3>

                    <AppTableRanking>
                      <div className="card-model">
                        <div
                          className="ranking-list-content ranking-list-content-custom-style custom-height-metrics"
                          ref={metricRankingRef}
                        >
                          {users.map((user, index) => (
                            <UserRanking
                              name={user.user.name}
                              lastName={user.user.lastName}
                              points={user.points}
                              current={sesion.user.id === user.userId}
                              positionIndex={index}
                              image={"/api" + user.userImage}
                              key={user.userId}
                            />
                          ))}
                        </div>
                      </div>
                    </AppTableRanking>
                  </span>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default Ranking;

